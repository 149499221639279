/**
 * Functionality to power the automatic and manual injection of editorial
 * defined widgets into the body of content.
 *
 * This is the file that Webpack is compiling into injector.[hash].js
 */

//  import "./sass/injector.scss";
import injectorMain from "./js/injector/injector-main";

$(document).ready(function () {
  const InjectorMainInstance = new injectorMain();
  InjectorMainInstance.init();
});
