/**
 * Javascript for InjectorPromo
 *
 * Always use .before to make at least one paragraph follow.
 **/

function InjectorPromo() {
  this.inject = (rule, ruleNum, article, widget) => {
    ruleNum = parseInt(ruleNum);

    switch (rule) {
      case "every":
        this.every(ruleNum, article, widget);
        break;
      case "after":
        this.after(ruleNum, article, widget);
        break;
      case "last":
        this.last(ruleNum, article, widget);
        break;
      case "percent":
        this.percent(ruleNum, article, widget);
        break;
    }
  };

  this.every = (ruleNum, article, widget) => {
    // if ($(window).width() >= 768) {
    // 	ruleNum += 1; // Increases the space on desktop.
    // }

    const mainContent = article.find('[data-js="main-content"]');
    const pCount = mainContent.children("p:not(:empty)").length;
    const galleryCount = mainContent.find(".gallery-slide").length;
    const commerceCount = mainContent.find(".commerce-gallery-item").length;
    const slideCount = galleryCount ? galleryCount : commerceCount; // Either gallery or commerce
    const slide = galleryCount ? ".gallery-slide" : ".commerce-gallery-item";

    if (pCount + slideCount < 3) {
      return;
    }

    // Every (N)th until the last 2nd paragraph or the last slide.
    const until = ruleNum < slideCount ? pCount + slideCount : pCount - 1;
    for (let index = ruleNum; index < until; index += ruleNum) {
      mainContent.find(`p, ${slide}`).eq(index).before(widget);
    }
  };

  this.after = (ruleNum, article, widget) => {
    const mainContent = article.find('[data-js="main-content"]');
    const pCount = mainContent.children("p:not(:empty)").length;
    const galleryCount = mainContent.find(".gallery-slide").length;
    const commerceCount = mainContent.find(".commerce-gallery-item").length;
    const slideCount = galleryCount ? galleryCount : commerceCount; // Either gallery or commerce
    const slide = galleryCount ? ".gallery-slide" : ".commerce-gallery-item";

    if (pCount + slideCount < 3) {
      return;
    }

    // After (N)th if there are more than (N) paragraphs/slides or ignore.
    mainContent
      .find(`p, ${slide}`)
      .eq(ruleNum - 1)
      .after(widget);
  };

  this.last = (ruleNum, article, widget) => {
    const mainContent = article.find('[data-js="main-content"]');
    const pCount = mainContent.children("p:not(:empty)").length;
    const galleryCount = mainContent.find(".gallery-slide").length;
    const commerceCount = mainContent.find(".commerce-gallery-item").length;
    const slideCount = galleryCount ? galleryCount : commerceCount; // Either gallery or commerce
    const slide = galleryCount ? ".gallery-slide" : ".commerce-gallery-item";

    if (pCount + slideCount < 3) {
      return;
    }

    // Last (N)th if there are more than (N x 2) paragraphs/slides, or at the end of the article.
    const posFromLast = pCount + slideCount >= ruleNum * 2 ? ruleNum : 0;
    if (posFromLast === 0) {
      mainContent.append(widget);
    } else {
      mainContent.find(`p, ${slide}`).eq(-posFromLast).before(widget);
    }
  };

  this.percent = (ruleNum, article, widget) => {
    const mainContent = article.find('[data-js="main-content"]');
    const pCount = mainContent.children("p:not(:empty)").length;
    const galleryCount = mainContent.find(".gallery-slide").length;
    const commerceCount = mainContent.find(".commerce-gallery-item").length;
    const slideCount = galleryCount ? galleryCount : commerceCount; // Either gallery or commerce
    const slide = galleryCount ? ".gallery-slide" : ".commerce-gallery-item";

    if (pCount + slideCount < 3) {
      return;
    }

    // (N)% from the start and after 3th paragraphs/slides
    const pos = Math.max(Math.ceil((pCount + slideCount) * (ruleNum / 100)), 3);
    mainContent.find(`p, ${slide}`).eq(pos).before(widget);
  };
}

export default InjectorPromo;
