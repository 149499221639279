/**
 * Javascript for InjectorVideos
 **/

function InjectorVideos() {
  this.injectorCallCount = 0;
  this.middleParagraph = (article, widget) => {
    this.injectorCallCount++;
    const mainContent = article.find('[data-js="main-content"]');
    const pCount = mainContent.children("p:not(:empty)").length;
    const galleryCount = mainContent.find(".gallery-slide").length;
    const commerceCount = mainContent.find(".commerce-gallery-item").length;
    const slideCount = galleryCount ? galleryCount : commerceCount; // Either gallery or commerce
    const slide = galleryCount ? ".gallery-slide" : ".commerce-gallery-item";

    if (pCount + slideCount >= 6) {
      let middle = Math.ceil((pCount + slideCount) / 2);
      // Add some separation between ads using "everyThird"
      if ($(window).width() >= 768) {
        if ((galleryCount && (middle - pCount) % 2 === 0) || middle % 4 === 0) {
          middle++;
        }
      } else if (middle % 3 === 0) {
        middle++;
      }
      if (pCount >= middle) {
        mainContent.children("p:eq(" + middle + ")").before(widget);
      } else {
        mainContent.find(`${slide}:eq(${middle - pCount})`).before(widget);
      }
    } else if (this.injectorCallCount === 1) {
      this.endParagraph(article, widget);
    }
  };
  this.endParagraph = (article, widget) => {
    this.injectorCallCount++;
    const mainContent = article.find('[data-js="main-content"]');
    const pCount = mainContent.children("p:not(:empty)").length;

    if (widget.includes("jw-video-box")) {
      // Remove message when the video were to put at the end.
      widget = $(widget).get(1);
    }

    // If there is a hero video, append video only when there are more than 6 paragraphs.
    if (!mainContent.find(".jw-video-box").length || pCount >= 6) {
      mainContent.children(".article__tags").before(widget);
    }
  };
}

export default InjectorVideos;
