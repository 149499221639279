/**
 * Javascript for InjectorCommerce
 **/

function InjectorCommerce() {
  this.lastThirdParagraph = (article, widget) => {
    const mainContent = article.find('[data-js="main-content"]');
    const pCount = mainContent.children("p:not(:empty)").length;
    const galleryCount = mainContent.find(".gallery-slide").length;
    const commerceCount = mainContent.find(".commerce-gallery-item").length;
    const slideCount = galleryCount ? galleryCount : commerceCount; // Either gallery or commerce
    const slide = galleryCount ? ".gallery-slide" : ".commerce-gallery-item";
    // Last 3rd if there are more than 6 paragraphs/slides, or at the end of the article.
    const posFromLast = pCount + slideCount >= 6 ? 3 : 1;
    if (slideCount > posFromLast) {
      mainContent.find(`${slide}:eq(-${posFromLast})`).before(widget);
    } else {
      mainContent.find(`p:eq(-${posFromLast - slideCount})`).before(widget);
    }
  };
}

export default InjectorCommerce;
