/**
 * Javascript for InjectorRightrail
 **/

function InjectorRightrail() {
  this.init = () => {
    if ($("#main article").hasClass("of_the_essence")) {
      return;
    }

    this.updateLayout();
    $(window).on("injector:scroll_update", this, this.updateLayout);
  };

  this.updateLayout = (event = null, $article = null) => {
    if (null === $article) {
      $article = $("#main article").first();
    }

    if ($article.hasClass("of_the_essence")) {
      return;
    }

    const isVideoArticle = $article.hasClass("video");
    const isFeatureArticle = $article.hasClass("feature");
  };

  this.inject = ($article, rightrail) => {
    $article.find('[data-js="rightrail"]').html(rightrail);
  };

  this.middleParagraph = ($article, rightrail) => {
    const mainContent = $article.find('[data-js="main-content"]');
    const pCount = mainContent.children("p:not(:empty)").length;
    const galleryCount = mainContent.find(".gallery-slide").length;
    const commerceCount = mainContent.find(".commerce-gallery-item").length;
    const slideCount = galleryCount ? galleryCount : commerceCount; // Either gallery or commerce
    const slide = galleryCount ? ".gallery-slide" : ".commerce-gallery-item";

    if (pCount + slideCount >= 6) {
      let middle = Math.ceil((pCount + slideCount) / 2);
      // Add some separation between ads using "everyThird"
      if ($(window).width() >= 768) {
        if ((galleryCount && (middle - pCount) % 2 === 0) || middle % 4 === 0) {
          middle++;
        }
      } else if (middle % 3 === 0) {
        middle++;
      }
      if (pCount >= middle) {
        /** To insert within paragraphs */
        const targetParagraph = mainContent.children("p:eq(" + middle + ")");

        // If there is only one paragraph after the banner and it is too short,
        // there will be too much space on the left of the banner due to floating.
        // In that case, if there is a paragraph before the banner, and move the banner up.
        if (
          !targetParagraph.next().is("p") &&
          targetParagraph.text().trim().split(/\s+/).length < 60 &&
          targetParagraph.prev().is("p")
        ) {
          targetParagraph = targetParagraph.prev();
        }

        targetParagraph.before(rightrail);
        mainContent.find(".read-more-blog").addClass("read-more-blog--float");
      } else {
        /** To insert within gallery items */
        mainContent.find(`${slide}:eq(${middle - pCount})`).before(rightrail);
      }
    }
  };
}

export default InjectorRightrail;
